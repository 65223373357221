import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const NikeAutocomplete = ({ id, labelText, multiple, options }) => (
  <Autocomplete
    multiple={multiple}
    id={id}
    size='small'
    options={options}
    getOptionLabel={(option) => option.label}
    renderInput={(params) => (
      <TextField
        {...params}
        variant='standard'
        label={labelText}
        placeholder='Favorites'
      />
    )}
  />
);

export default NikeAutocomplete;

NikeAutocomplete.propTypes = {
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
};
