import React from 'react';
import { useAuth, useAuthStore } from '@nike/aegis-auth-react'
import { ToastContainer, toast } from 'react-toastify'
import client from '../auth/client'
import Spinner from '../components/Spinner'
// import { useHistory } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
import Generic from '../components/errors/Error'
import OfflineBanner from '../offline/offline';
// import { GenericError, Spinner, Login } from '../components';

export const App = ({ children }) => {
  const { loginComplete } = useAuth(client)
  const { accessToken, loginError } = useAuthStore()
  
  // const history = useHistory();
  // const previousLocation = useSelector(
  //   (state) => state.auth.previousLocation
  // );
  // useEffect(() => {
  //     if (isLoggedIn) {
  //         history.push(previousLocation || '/');
  //     }
  //     // eslint-disable-next-line
  // }, [isLoggedIn, previousLocation]);

  if (loginError) {
    return (
      <div>
        <Generic
          title="Login Failed!"
          error ={loginError} />
        <ToastContainer>
          { toast('Login Failed!', {
            type: 'error',
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          })}</ToastContainer>

      </div>
    )
  }

  if (loginComplete) {
    // setGlobalHeader('x-api-key', process.env.REACT_APP_BOOKINGS_API_KEY || '')
    // setGlobalHeader('access-control-allow-origin', '*')
    // setGlobalHeader('Authorization', 'Bearer ' + accessToken)
    console.log(accessToken)
  } else {
    return <Spinner large />
  }

  return (
    <>
      <OfflineBanner />
      {children}
    </>
  );
};

export default App;