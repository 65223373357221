import React from 'react';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import { common } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  // footerContainer: {
  //   zIndex: theme.zIndex.drawer + 1,
  //   backgroundColor: theme.palette.primary.main,
  //   padding: '1rem 1rem',
  //   color: common.white,
  //   top: 'auto',
  //   bottom: 0,
  // },
}));

export default () => {
  const classes = useStyles();
  return (
    <AppBar data-e2e="footer" className={classes.footerContainer}>
      {/* <Typography variant="body2">
        Copyright ©{new Date().getFullYear()}
        &nbsp;Nike Inc. All rights reserved.
      </Typography> */}
    </AppBar>
  );
};
