import React from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  NikeButton,
  NikeAutocomplete,
  NikeSelectField,
  NikeGrid,
} from '../../components';
import { showSnackBar } from '../../notifications/snackbarSlice';
// import DateAndTimePicker from '../myProducts/DateAndTimePicker';
// import { mount, shallow } from 'enzyme';
import PizzaTracker, { PizzaTrackerPure } from '@nike/nr-vamp-xl-pizza-tracker';
// import PizzaTracker, { PizzaTrackerPure } from '../myProducts/PizzaTrackerPure';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Avatar from '@material-ui/core/Avatar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginLeft: '50px',
    marginRight: '50px',
    marginTop: '30px',
    marginBottom: '5px',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

// const converted = {
//   '.tooltip .tooltiptext': {
//     visibility: 'hidden',
//     width: '120px',
//     backgroundColor: 'black',
//     color: '#fff',
//     textAlign: 'center',
//     borderRadius: '6px',
//     padding: '5px 0',
//     position: 'absolute',
//     zIndex: '1',
//     bottom: '100%',
//     left: '50%',
//     marginLeft: '-60px',
//   },
//   '.tooltip:hover .tooltiptext': { visibility: 'visible' },
// };
function changeBackground(e) {
  e.target.style.background = 'white';
}

export default function CenteredGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h1" className="display-1" gutterBottom>
          <div
            style={{
              marginTop: '100px',
              marginBottom: '2px',
              textAlign: 'left',
              marginLeft: '50px',
              backgroundColor: '#FFFFFF',
              fontSize: '50%',
            }}
          >
            POS Data Refresh Status
          </div>

          <div
            style={{
              // marginTop: '50px',
              marginBottom: '10px',
              textAlign: 'left',
              marginLeft: '50px',
              // backgroundColor: '#FFFFFF',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontWeight: '700',
              fontSize: '20%',
              color: '#696969',
              textTransform: 'capitalize',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>POS 3.0 Dashboard</span>
            <span
              style={{
                marginRight: '50px',
              }}
            ></span>
          </div>
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <div
                style={{
                  // marginTop: '50px',
                  // marginBottom: '50px',
                  textAlign: 'left',
                  marginLeft: '50px',
                  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
                  fontWeight: '600',
                  fontSize: '30px',
                  color: '#000000',
                  textTransform: 'capitalize',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span>POS Door Aggregate</span>

                <span
                  style={{
                    textAlign: 'right',
                    marginLeft: '50px',
                    fontFamily:
                      '"Helvetica Neue", Helvetica, Arial, sans-serif',
                    fontWeight: '500',
                    fontSize: '20px',
                    color: 'black',
                    textTransform: 'capitalize',
                  }}
                >
                  {/* Expected Delivery Date 08/05/20 04:45PM */}
                </span>
                <button onMouseOver={changeBackground}>
                  <Avatar
                    alt="Snowflake"
                    src="https://www.klipfolio.com/sites/default/files/integrations/snowflake.png"
                  />
                </button>
              </div>

              <div>
                <PizzaTracker
                  data-e2e="PizzaTracker-simple-story"
                  id="PizzaTracker-simple-story"
                  style={{ margin: '100px' }}
                  // onClick={alert('clicked tracker')}

                  stepItems={[
                    {
                      label: 'Loading Data From Fact',
                      indicator: 'success',

                      // onClick: alert('hi'),
                    },

                    {
                      label: <p>Calculate CY/PY</p>,
                      // onClick: console.log('clicked'),
                      indicator: 'success',
                    },

                    {
                      label: 'Loading Door Aggregate',
                      indicator: 'success',
                    },
                    {
                      label: 'Loading Division Process',
                      indicator: 'success',
                    },
                    {
                      label: 'Loading Channel Process',
                      indicator: 'success',
                    },
                    {
                      label: 'Door/Summary/Banner Aggregate',
                      indicator: 'success',
                    },
                  ]}
                />
              </div>

              <CardActions>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardContent>
                      <Typography
                        style={{ textAlign: 'left' }}
                        gutterBottom
                        variant="h5"
                        component="h2"
                      >
                        Accounts Processing
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{
                          textAlign: 'left',
                          fontFamily:
                            '"Helvetica Neue", Helvetica, Arial, sans-serif',
                          fontSize: '20px',
                          color: '#696969',
                          textTransform: 'capitalize',
                        }}
                      >
                        Dicks, Fred Meyer, Footlocker
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions></CardActions>
                </Card>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardContent>
                      <Typography
                        style={{ textAlign: 'left' }}
                        gutterBottom
                        variant="h5"
                        component="h2"
                      >
                        Current Swap Table
                      </Typography>
                      <Typography
                        style={{ textAlign: 'left' }}
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{
                          textAlign: 'left',
                          fontFamily:
                            '"Helvetica Neue", Helvetica, Arial, sans-serif',
                          fontSize: '20px',
                          color: '#696969',
                          textTransform: 'capitalize',
                        }}
                      >
                        Table A
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </CardActions>
            </Paper>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div
                  style={{
                    textAlign: 'left',
                    marginLeft: '50px',
                    fontFamily:
                      '"Helvetica Neue", Helvetica, Arial, sans-serif',
                    fontWeight: '600',
                    fontSize: '30px',
                    color: '#000000',
                    textTransform: 'capitalize',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>POS Channel Aggregate</span>
                  <button onMouseOver={changeBackground}>
                    <Avatar
                      alt="Snowflake"
                      src="https://www.klipfolio.com/sites/default/files/integrations/snowflake.png"
                    />
                  </button>
                  {/* <span
                    style={{
                      textAlign: 'right',
                      marginLeft: '50px',
                      fontFamily:
                        '"Helvetica Neue", Helvetica, Arial, sans-serif',
                      fontWeight: '700',
                      fontSize: '20px',
                      color: '#696969',
                      textTransform: 'capitalize',
                    }}
                  >
                    Expected Delivery Date 08/05/20 04:45PM
                  </span> */}
                </div>

                <div>
                  <PizzaTracker
                    data-e2e="PizzaTracker-simple-story"
                    id="PizzaTracker-simple-story"
                    style={{ margin: '100px' }}
                    // onClick={alert('clicked tracker')}

                    stepItems={[
                      {
                        label: 'Checkpoint 1',
                        indicator: 'success',
                      },

                      {
                        label: <p>Checkpoint 2</p>,
                        // onClick: console.log('clicked'),
                        indicator: 'success',
                      },

                      {
                        label: 'Checkpoint 3',
                        indicator: 'success',
                      },
                      {
                        label: 'Checkpoint 4',
                        indicator: 'warning',
                      },
                      {
                        label: 'Checkpoint 5',
                      },
                    ]}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div
                  style={{
                    // marginTop: '50px',
                    // marginBottom: '50px',
                    textAlign: 'left',
                    marginLeft: '50px',
                    fontFamily:
                      '"Helvetica Neue", Helvetica, Arial, sans-serif',
                    fontWeight: '600',
                    fontSize: '30px',
                    color: '#000000',
                    textTransform: 'capitalize',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>Door Info Retriever</span>
                  <span
                    style={{
                      textAlign: 'right',
                      marginLeft: '50px',
                      fontFamily:
                        '"Helvetica Neue", Helvetica, Arial, sans-serif',
                      fontWeight: '700',
                      fontSize: '20px',
                      color: '#696969',
                      textTransform: 'capitalize',
                    }}
                  >
                    Expected Delivery Date 08/05/20 04:45PM
                  </span>
                </div>

                <div>
                  <PizzaTracker
                    data-e2e="PizzaTracker-simple-story"
                    id="PizzaTracker-simple-story"
                    style={{ margin: '100px' }}
                    // onClick={alert('clicked tracker')}

                    stepItems={[
                      {
                        label: 'Checkpoint 1',
                        indicator: 'success',

                        // onClick: alert('hi'),
                      },

                      {
                        label: <p>Checkpoint 2</p>,
                        // onClick: console.log('clicked'),
                        indicator: 'success',
                      },

                      {
                        label: 'Checkpoint 3',
                        indicator: 'success',
                      },
                      {
                        label: 'Checkpoint 4',
                        indicator: 'warning',
                      },
                      {
                        label: 'Checkpoint 5',
                        indicator: 'danger',
                      },
                    ]}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div
                  style={{
                    // marginTop: '50px',
                    // marginBottom: '50px',
                    textAlign: 'left',
                    marginLeft: '50px',
                    fontFamily:
                      '"Helvetica Neue", Helvetica, Arial, sans-serif',
                    fontWeight: '600',
                    fontSize: '30px',
                    color: '#000000',
                    textTransform: 'capitalize',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>Channel Info Retriever</span>
                  <span
                    style={{
                      textAlign: 'right',
                      marginLeft: '50px',
                      fontFamily:
                        '"Helvetica Neue", Helvetica, Arial, sans-serif',
                      fontWeight: '700',
                      fontSize: '20px',
                      color: '#696969',
                      textTransform: 'capitalize',
                    }}
                  >
                    Expected Delivery Date 08/05/20 04:45PM
                  </span>
                </div>

                <div>
                  <PizzaTracker
                    data-e2e="PizzaTracker-simple-story"
                    id="PizzaTracker-simple-story"
                    style={{ margin: '100px' }}
                    // onClick={alert('clicked tracker')}
                    stepItems={[
                      {
                        label: 'Checkpoint 1',
                        indicator: 'success',

                        // onClick: alert('hi'),
                      },

                      {
                        label: <p>Checkpoint 2</p>,
                        // onClick: console.log('clicked'),
                        indicator: 'danger',
                      },

                      {
                        label: 'Checkpoint 3',
                      },
                      {
                        label: 'Checkpoint 4',
                      },
                      {
                        label: 'Checkpoint 5',
                      },
                    ]}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div
                  style={{
                    // marginTop: '50px',
                    // marginBottom: '50px',
                    textAlign: 'left',
                    marginLeft: '50px',
                    fontFamily:
                      '"Helvetica Neue", Helvetica, Arial, sans-serif',
                    fontWeight: '600',
                    fontSize: '30px',
                    color: '#000000',
                    textTransform: 'capitalize',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>Size Info Retriever</span>
                  <span
                    style={{
                      textAlign: 'right',
                      marginLeft: '50px',
                      fontFamily:
                        '"Helvetica Neue", Helvetica, Arial, sans-serif',
                      fontWeight: '700',
                      fontSize: '20px',
                      color: '#696969',
                      textTransform: 'capitalize',
                    }}
                  >
                    Expected Delivery Date 08/05/20 04:45PM
                  </span>
                </div>

                <div>
                  <PizzaTracker
                    data-e2e="PizzaTracker-simple-story"
                    id="PizzaTracker-simple-story"
                    style={{ margin: '100px' }}
                    // onClick={alert('clicked tracker')}

                    stepItems={[
                      {
                        label: 'Checkpoint 1',

                        // onClick: alert('hi'),
                      },

                      {
                        label: <p>Checkpoint 2</p>,
                      },

                      {
                        label: 'Checkpoint 3',
                      },
                      {
                        label: 'Checkpoint 4',
                      },
                      {
                        label: 'Checkpoint 5',
                      },
                    ]}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>

          <Grid
            style={{
              marginTop: '2px',
            }}
            item
            xs={6}
          >
            <Paper className={classes.paper}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image="/static/images/cards/contemplative-reptile.jpg"
                    title="Refresh"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Past Refresh
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{
                        fontFamily:
                          '"Helvetica Neue", Helvetica, Arial, sans-serif',
                        fontSize: '20px',
                        color: '#696969',
                        textTransform: 'capitalize',
                      }}
                    >
                      08/09/2020 02:00PM
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  {/* <Button>Click Here</Button>
                  <Button size="small" color="black">
                    Learn More
                  </Button> */}
                </CardActions>
              </Card>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image="/static/images/cards/contemplative-reptile.jpg"
                    title="Refresh"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Next Refresh
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{
                        fontFamily:
                          '"Helvetica Neue", Helvetica, Arial, sans-serif',
                        fontSize: '20px',
                        color: '#696969',
                        textTransform: 'capitalize',
                      }}
                    >
                      Start Time: 08/11/2020 5:00AM
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  {/* <Button>Click Here</Button>
                  <Button size="small" color="black">
                    Learn More
                  </Button> */}
                </CardActions>
              </Card>

              {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Next Refresh
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Start Time Scheduled for:{' '}
                    <strong> Thursday, August 06 2020 </strong>{' '}
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
            </Paper>
          </Grid>
          {/* <Grid item xs={3}>
            <Paper className={classes.paper}>xs=3</Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.paper}>xs=3</Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.paper}>xs=3</Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.paper}>xs=3</Paper>
          </Grid> */}
        </Grid>
      </div>
    </div>
  );
}
