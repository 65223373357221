import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { common } from '@material-ui/core/colors';

const drawerWidth = 180;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(10) + 1,
    },
  },
  toolbar: {
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    paddingTop: 100,
  },
  navigationIcon: {
    width: 28,
    height: 28,
    marginLeft: 8,
    marginTop: 4,
    marginBottom: 4,
    '&:hover': {
      color: theme.palette.secondary.main,
      transition: '0.3s !important',
    },
  },
  activeLink: {
    '& $div': {
      color: theme.palette.secondary.main,
    },
  },
  navHeader: {
    marginTop: 8,
    marginBottom: 16,
  },
  navItem: {
    marginTop: 8,
    marginBottom: 8,
  },
}));

const NavigationBar = ({ navLinks }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div className={classes.toolbar} />
      {navLinks &&
        navLinks.map(({ to, label, icon }) => (
          <ListItem
            button
            key={label}
            onClick={() => setOpen(false)}
            component={NavLink}
            to={to}
            exact
            activeClassName={classes.activeLink}
          >
            <ListItemIcon className={classes.navigationIcon}>
              {icon}
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
    </Drawer>
  );
};

export default NavigationBar;
