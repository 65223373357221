import { createSlice } from '@reduxjs/toolkit';

const snackbarSlice = createSlice({
  name: 'snackbarSlice',
  initialState: {
    type: 'success',
    message: '',
  },
  reducers: {
    showSnackBar(state, action) {
      const { payload } = action;
      const { type, message } = payload;
      state.type = type;
      state.message = message;
    },
    removeSnackBar(state, action) {
      state.message = '';
    },
  },
});

export const getSnackBar = (state) => state.snackbarSlice;
export const { showSnackBar, removeSnackBar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
