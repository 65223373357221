import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import axios from 'axios';

import rootReducer from './reducer';
import config from '../config/oAuthConfig';

const history = createBrowserHistory();


const store = configureStore({
  reducer: rootReducer(createBrowserHistory())
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducer', () => {
    const newRootReducer = require('./reducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export default store;