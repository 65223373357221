import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const NikeButton = ({ type, size = 'small', children, onClick }) => (
  <div style={{ marginBottom: 16, marginLeft: 16 }}>
    <Button
      variant={type === 'primary' ? 'contained' : 'outlined'}
      color={type}
      style={{ minWidth: '150px !important' }}
      size={size}
      onClick={onClick}
    >
      {children}
    </Button>
  </div>
);

export default NikeButton;

NikeButton.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};
