import React, { useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { deepOrange, common, grey } from '@material-ui/core/colors';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
// import useDarkMode from 'use-dark-mode';
import { faMoon, faSun, faTh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import config from '../../config/oAuthConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    paddingRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  avatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontWeight: 400,
    fontSize: 15,
  },
  userName: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '42px',
  },
  swoosh: {
    color: '#111111',
    display: 'flex',
    flexFlow: 'column nowrap',
    fontSize: 18,
    justifyContent: 'center',
    marginBottom: 0,
    marginTop: 0,
    width: theme.spacing(10),
  },
  linkContainer: {
    alignItems: 'center',
    display: 'flex',
    position: 'absolute',
    left: 350,
  },
  activeLink: {
    color: '#111111',
    paddingLeft: 15,
    paddingRight: 15,
    textTransform: 'uppercase',
  },
  inactiveLink: {
    color: '#111111',
    paddingLeft: 15,
    paddingRight: 15,
    textTransform: 'uppercase',
  },
  darkToggle: {
    marginRight: 8,
  },
  popover: {
    position: 'absolute',
    top: '20px !important',
  },
  progressBar: {
    height: 6,
  },
}));

export const Index = ({ navLinks, apps, email, userName }) => {
  const classes = useStyles();
  const [showDropDown, toggleShowDropDown] = useState(null);
  // const darkMode = useDarkMode(false);
  const { promiseInProgress } = usePromiseTracker();
  return (
    <>
      <AppBar
        data-e2e="header"
        id="header"
        position="fixed"
        className={classes.root}
      >
        <Toolbar>
          <i className={`g72-swoosh ${classes.swoosh}`} />
          <Typography variant="h4">{config.appName}</Typography>
          <div className={classes.linkContainer}>
            {navLinks &&
              navLinks.map(({ to, label }) => (
                <NavLink
                  key={to}
                  data-e2e={to}
                  strict
                  to={to}
                  exact
                  className={classes.inactiveLink}
                  activeClassName={classes.activeLink}
                >
                  <Typography variant="h6">{label}</Typography>
                </NavLink>
              ))}
          </div>
          <div className={classes.grow} />

          <div
            onClick={({ currentTarget }) => {
              toggleShowDropDown(showDropDown ? null : currentTarget);
            }}
          >
            <Typography variant="h5" className={classes.userName}>
              {userName && userName.split(' ')[0]}
            </Typography>
          </div>
          {/* {apps ? (
            <FontAwesomeIcon icon={faTh} />
          ) : (
            email && (
              <Avatar
                data-e2e="userAvatar"
                className={`ml4-sm ${classes.avatar}`}
              >
                {userName &&
                  userName.split(' ')[0][0] + userName.split(' ')[1][0]}
              </Avatar>
            )
          )} */}
          {Boolean(showDropDown) && (
            <Popover
              open
              anchorEl={showDropDown}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              className={classes.popover}
              onClose={() => toggleShowDropDown(null)}
            >
              <Paper elevation={5}>
                <ListItem
                  key="logout"
                  data-e2e="logout"
                  className="listItem"
                  button
                  onClick={() => {
                    toggleShowDropDown(null);
                  }}
                >
                  <ListItemText primary="Logout" />
                </ListItem>
              </Paper>
            </Popover>
          )}
        </Toolbar>
      </AppBar>
      {promiseInProgress && (
        <LinearProgress color="secondary" className={classes.progressBar} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    
  };
};

const ConnectedComponent = connect(mapStateToProps)(Index);
export default ConnectedComponent;
