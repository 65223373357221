import { createSlice } from '@reduxjs/toolkit';

const offlineSlice = createSlice({
  name: 'isOffline',
  initialState: { offline: false },
  reducers: {
    notifyOffline(state, action) {
      const { payload } = action;
      state.offline = payload;
    },
  },
});

export const isOffline = (state) => state.isOffline.offline;
export const { notifyOffline } = offlineSlice.actions;
export const watchOnlineState = (store) => {
  const notify = () => store.dispatch(notifyOffline(!navigator.onLine));

  // Initialize the state
  notify();

  // There is a problem with registering these events
  // If you try attach them before 'load' fires they just no-op (Edge/Firefox)
  // If 'load' has already fired it will not fire again
  // To ensure they work in all cases you must do both (yay browser inconsistencies!)
  // To ensure you are not double notified 'load' must also clean up the original listeners
  window.addEventListener('online', notify);
  window.addEventListener('offline', notify);

  window.addEventListener('load', () => {
    window.removeEventListener('online', notify);
    window.removeEventListener('offline', notify);
    window.addEventListener('online', notify);
    window.addEventListener('offline', notify);
  });
};

export default offlineSlice.reducer;
