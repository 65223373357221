// const port = process.env.REACT_APP_apiPort
//   ? `:${process.env.REACT_APP_apiPort}`
//   : '';

// const base = {
//   appName: 'MPA Products',
//   appFullName: 'North America Marketplace Analytics Product App',
//   apiUrl: `${window.location.protocol}//${window.location.hostname}${port}/api/graphql`,
//   oAuth: {
//     authorization: `${process.env.REACT_APP_OKTA_AUTH_URL}/authorize`,
//     token: `${process.env.REACT_APP_OKTA_AUTH_URL}/token`,
//     userInfo: `${process.env.REACT_APP_OKTA_AUTH_URL}/userinfo`,
//     client_id: process.env.REACT_APP_OKTA_CLIENT_ID,
//     response_type: 'authorization_code',
//     keepToken: true,
//     scope: 'profile openid email',
//     redirect_uri: `${window.location.origin}/okta-redirect`,
//   },
// };

// export default base;


import merge from 'deepmerge'

const base = {
  appName: 'NA Web Portal',
  appFullName: 'Web Portal',
  oAuth: {
    clientId: process.env.REACT_APP_CLIENT_ID || 'nike.naanalytics.webportal'
  }
}

const dev = merge(base, {
  //apiUrl: process.env.REACT_APP_API_URL || 'https://na-eda-api-test.nikecloud.com/search/bookings',
  oAuth: {
    redirectUri: process.env.REACT_APP_WEB_URL || window.location.origin,
    qa: true
  }
})

const prod = merge(base, {
  //apiUrl: process.env.REACT_APP_API_URL,
  oAuth: {
    redirect_uri: process.env.REACT_APP_WEB_URL
  }
})

const config = window.location.origin.includes('nike.com') ? prod : dev // (process.env.NODE_ENV === 'production') ? prod : dev

export default config
