import { useSelector } from 'react-redux';
import React from 'react';
import { isOffline } from './offlineSlice';

import './offline.scss';

const OfflineBanner = () => {
  const offline = useSelector(isOffline);
  return offline ? (
    <div className='ncss-container mb2-sm offline-section text-color-primary-light d-lg-flx flx-jc-lg-c'>
      <span className='fs20-sm u-sm-ib pr4-sm pl4-sm'>
        <i className='g72-alert' />
      </span>
      <span className='ncss-brand'>OFFLINE MODE</span>
      <span className='pl2-sm'>functionality may be limited</span>
    </div>
  ) : null;
};

export default OfflineBanner;
