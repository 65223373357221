import React from 'react'

const Generic = ({ error }) => {
  return (
    <div>
      <h1>An error occured, unable to load page.</h1>
      {error ? <pre>{error.message || error}</pre> : null}
    </div>
  )
}

export default Generic