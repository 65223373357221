import React, { useMemo } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  createMuiTheme,
  MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import { grey, common } from '@material-ui/core/colors';
import NikeTheme from '@nike/mui-theme';
// import useDarkMode from 'use-dark-mode';
import Router from './routes';
import appHistory from '../utilities/history';

import ConnectedApp from './App';

const Root = ({ store }) => {
  // const darkMode = useDarkMode(false);
  const secondaryColor = '#FFFFFF';
  const dsmTheme = useMemo(
    () =>
      createMuiTheme({
        ...NikeTheme,
        palette: {
          // type: darkMode.value ? 'dark' : 'light',
          primary: {
            main: '#E5E5E5',
          },
          secondary: {
            main: secondaryColor,
          },
          background: {
            default: secondaryColor,
          },
        },

        typography: {
          fontFamily: [
            'Helvetica Neue',
            'sans-serif',
            'Helvetica',
            'Arial',
          ].join(', '),
          h1: {
            fontFamily: [
              'Nike Futura',
              'Nike TG',
              'Helvetica Neue',
              'sans-serif',
              'Helvetica',
              'Arial',
            ].join(', '),
          },
          h2: {
            fontFamily: [
              'Nike Futura',
              'Nike TG',
              'Helvetica Neue',
              'sans-serif',
              'Helvetica',
              'Arial',
            ].join(', '),
          },
          h3: {
            fontFamily: [
              'Nike TG',
              'Helvetica Neue',
              'sans-serif',
              'Helvetica',
              'Arial',
            ].join(', '),
          },
          h4: {
            fontFamily: [
              'Nike TG',
              'Helvetica Neue',
              'sans-serif',
              'Helvetica',
              'Arial',
            ].join(', '),
          },
          h5: {
            fontFamily: [
              'Nike TG',
              'Helvetica Neue',
              'sans-serif',
              'Helvetica',
              'Arial',
            ].join(', '),
          },
          h6: {
            fontFamily: [
              'Nike TG',
              'Helvetica Neue',
              'sans-serif',
              'Helvetica',
              'Arial',
            ].join(', '),
          },
        },
        shape: {
          borderRadius: 0,
        },
        overrides: {
          MuiButton: {
            root: {
              fontFamily: [
                'Nike TG',
                'Helvetica Neue',
                'Helvetica',
                'Arial',
                'sans-serif',
              ].join(', '),
              width: '260px',
              height: '260px',
              backgroundColor: '#f7f7f7',
              marginTop: '100px',
              borderRadius: '30px',

              boxShadow: '5px 5px 5px grey',
              display: 'flex',

              flexDirection: 'row',
              justifyContent: 'space-evenly',
              flexWrap: 'wrap',
            },

            containedPrimary: {
              '&:hover': {
                backgroundColor: secondaryColor,
                transition: '0.3s !important',
              },
            },
            outlined: {
              color: '#111111',
              border: '1px solid',
              borderColor: '#f7f7f7',
              '&:hover': {
                backgroundColor: secondaryColor,
                transition: '0.3s !important',
              },
            },
          },
          MuiAutocomplete: {
            root: {
              width: '400px',
            },
          },
          MuiSelect: {
            root: {
              width: '400px',
            },
          },
          MuiTextField: {
            root: {
              width: '400px',
            },
          },
        },
      })
    // [darkMode.value]
  );

  return (
    <ReduxProvider store={store}>
      <StylesProvider>
        <MuiThemeProvider theme={dsmTheme}>
          <CssBaseline />
          <Router component={ConnectedApp} history={appHistory} />
        </MuiThemeProvider>
      </StylesProvider>
    </ReduxProvider>
  );
};

export default Root;