/* eslint react/destructuring-assignment:0 */
import React from 'react';

const Spinner = (props) => (
  <div
    className={`spinner ${props.large ? 'spinner-lg' : ''} ${
      props.className || ''
    }`}
  />
);

export default Spinner;
