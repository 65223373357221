import React, { Suspense, lazy, useEffect } from 'react';
import { Router, Route, Switch, useHistory } from 'react-router-dom';
// import Progress from '@nike/nr-vamp-xl-progress';
import Progress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@material-ui/core';

// import Snackbar from '../notifications/Snackbar';
import { AppBar, Footer, GenericError, Login, Spinner } from '../components';
import './App.scss';
import StandardProduct from '../features/myProducts/index';

import history from '../root/history';

const AsyncHome = lazy(() => import('../features/home'));
// const AsyncUser = lazy(() => import('../features/users'));
const AsyncAbout = lazy(() => import('../features/about'));
// const AsyncMyProducts = lazy(() => import('../features/myProducts'));

const routes = [
  {
    path: '/',
    title: 'Home',
    component: AsyncHome,
    nav: true,
    exact: true,
    secure: true,
  },
  // {
  //   path: '/user',
  //   title: 'User',
  //   component: AsyncUser,
  //   nav: true,
  //   exact: true,
  //   secure: true,
  // },
  {
    path: '/about',
    title: 'About',
    component: AsyncAbout,
    nav: true,
    exact: true,
    secure: true,
  },
  // {
  //   path: '/nampaProducts',
  //   title: 'My Products',
  //   component: AsyncMyProducts,
  //   nav: true,
  //   exact: true,
  //   secure: true,
  // },
];

const navLinks = routes
  .filter((r) => r.nav)
  .map((r) => ({ to: r.path, label: r.title }));

const Routes = ({ component }) => {
  const Component = component;
  const dispatch = useDispatch();
  

  return (
    <Router history={history}>
      <Switch>
        <Route path="/nampaProducts" component={StandardProduct} />
      </Switch>
      <Component>
        <>
          <AppBar data-e2e="header" navLinks={navLinks} />
          <Container maxWidth="xl" className="App-header">
            <Suspense
              fallback={
                <Progress
                  data-e2e="progress-linear-indeterminate-story"
                  id="progress-linear-indeterminate-story"
                  type="linear"
                />
              }
            >
              <Switch>
                {routes.map((route) => {
                  return (
                    <Route
                      key={route.path}
                      title={route.title}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  );
                })}
              </Switch>
            </Suspense>
          </Container>
          {/* <Snackbar /> */}
          <Footer />
        </>
      </Component>
    </Router>
  );
};

export default Routes;