import { createBrowserHistory } from 'history';

import store from '../root/store';

const _history = createBrowserHistory();

function addLocationQuery(history) {
    store.dispatch({
        type: "",
        payload: history.location.pathname,
    });
}

addLocationQuery(_history);

_history.listen(() => {
    addLocationQuery(_history);
});

export default _history;
